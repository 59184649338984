body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.custom-toolbar{
  .custom-navbar {
    border-radius: 0;
    z-index: 9999;
    position: fixed;
  }

  .navbar-nav {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 10px 0;
  }
  .navbar-default .navbar-nav > li > a{
    padding:20px;
  }

  .navbar-right {
    width: 100%;
  }

  .nav-link {
    padding: 21px;
    position: relative;
    color: #333;
    transition: color 0.3s ease;
  }

  .nav-link.active {
    background-color: #f8f8f8;
  }

  .nav-link.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    color:red;
    transition: opacity 0.3s ease;
  }

  @media (min-width: 768px) {
    .container-fluid{
      display:flex;
      align-items:center;
    }
    .navbar-header{
      min-width: auto!important;
    }
    .navbar-header {
      float: left;
      display: flex;
      align-items: center;
      margin-right: 15px;
    }

    .navbar-collapse {
      display: flex !important;
      align-items: center;
    }

    .navbar-overlay {
      display: none;
    }
    .navbar-nav {
      flex-direction: row;
      width: auto;
      padding: 0;
      margin: 0;
    }

    .navbar-right {
      width: auto;
    }
  }

  @media (max-width: 768px) {
    .navbar-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.1);
      z-index: 9998;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease, visibility 0.3s ease;
    }

    .navbar-overlay.show {
      opacity: 1;
      visibility: visible;
    }
    .navbar-nav{
      border-bottom: unset!important;
    }
    .navbar-nav li {
      width: 100%;
      text-align: left;
    }

    .nav-link {
      width: 100%;
      display: block;
    }

    .nav-link.active::after {
      width: 4px;
      height: 100%;
      left: 0;
      top: 0;
    }
    .navbar-header{
      padding:4px 0;
    }

    .navbar-header img{
        border-radius:8px!important;
        margin-left:16px;
    }
    .navbar-toggle{
      padding:8px 10px!important;
    }
  }
}
